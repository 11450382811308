import React from 'react';
import Layout from '../components/layout';
import { alternateLinks } from '../data/site-data';
import Hero from '../components/common/hero';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Anwalt für Auswanderung in die USA | Pandev Law',
  description:
    'Möchten Sie in die USA auswandern? Pandev law ist eine Einwanderungsanwaltskanzlei in New York und South Carolina mit langjähriger Erfahrung. Rufen Sie uns bitte an.',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  alternateLinks,
};

const schema = `{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Anwalt für Auswanderung in die USA",
  "image": "https://www.pandevlaw.com/img/statue-liberty.jpg",
  "description": "Möchten Sie in die USA auswandern? Pandev law ist eine Einwanderungsanwaltskanzlei in New York und South Carolina mit langjähriger Erfahrung. Rufen Sie uns bitte an.",
  "brand": "Pandev Law",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  }
}`;

function German({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema} lang="de-DE">
      <Hero
        title="Ihre Reise nach Amerika beginnt hier"
        isTitleBgTransparent
        hideContactLink>
        <div className="text-bg-white">
          <p className="text-lead pt-lg-8 mb-5 mb-md-8">
            <strong>Pandev Law</strong> ist eine Anwaltskanzlei spezialisiert in
            Einwanderung und Wirtschaft, die für eineaußerordentlich hochwertige
            Vertretung steht. Das Motto &ldquo;Ihre Reise nach Amerika beginnt hier&rdquo;
            sagt alles.
            <br />
            <br />
            Ich helfe internationalen Unternehmen und Einzelpersonen in die USA zu ziehen.
            Ich bin einzigartig positioniert, um Einzel-und Firmenkunden in den
            Vereinigten Staaten und weltweit effektiv zu vertreten.
          </p>
        </div>
      </Hero>

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <p className="text-body">
              Meine Einwanderungspraxis konzentriert sich auf Investoren und
              beschäftigungsbasierte Einwanderung, insbesondere EB-5-Investoren und
              E-2-Investoren, H-1B-Spezialarbeiter und L-1-Konzernintern versetzte
              Arbeiter. Außerdem helfe ich Familien mit Ehe-, Verlobungs- und anderen
              Familen basierten Anträgen. Ich habe umfangreiche Erfahrung mit dem
              beschäftigungsbasierten Green Card-Verfahren, einschließlich PERM
              Dauerarbeitszeugnissen, EB-2 Ausnahmegenehmigungen für Nationalstaatliche
              Interessen und EB-1-Petitionen für multinationale Manager und Einzelpersonen
              mit außergewöhnlichen Fähigkeiten, wie Akademiker, Forscher,
              Wissenschaftler, Künstler und Sportler.
              <br />
              <br />
              Meine Geschäftspraxis konzentriert sich auf die Beratung von Unternehmern,
              sowie kleinen und mittleren Firmen bei der Gründung und Führung, bei
              Fusionen und Übernahmen, und beikommerziellen Transaktionen und
              Finanzierungen. Ich berate regelmäßig bei grenzüberschreitenden
              Transaktionen und vertrete ausländische Unternehmen, die in den US-Markt
              einsteigen möchten. Ich berate in Bezug auf Import-und Exportkonformität und
              helfe bei angeblichen Verstößen gegen das Zollrecht.
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-body">
              Bevor ich Pandev Law gründete, war ich als Prozessanwalt beim
              US-Justizministerium, dem Amt für Einwanderung und Rechtstreitigkeiten in
              Washington, DC tätig. Als Prozessstaatsanwalt vertrat ich Regierungsbehörden
              - einschließlich des US-Außenministeriums, des US-Zoll-und Grenzschutzes
              sowie der US-Staatsbürgerschaft und der Einwanderungsbehörden - in
              Rechtsstreitigkeiten vor Bundesbezirksgerichten und Bundesbeschwerdekammern
              in den Vereinigten Staaten. Zuvor hatte ich Positionen beim
              US-Arbeitsministerium, dem Atlanta Einwanderungsgericht, dem New Yorker Büro
              einer großen internationalen Anwaltskanzlei, einer Einwanderungskanzlei in
              Washington, und der größten Einwanderungskanzlei der Welt.
              <br />
              <br />
              Meine Grundschuljahre verbrachte ich an einer deutschen internationalen
              Schule und spreche fließend Deutsch. Ich habe bereits zahlreiche
              deutschsprachige Kunden betreut und würde Sie gerne in Ihrer Muttersprache
              beraten.
              <br />
              <br />
              Wenn Sie uns kontaktieren möchten, senden Sie uns eine E-Mail an{' '}
              <a className="link-primary" href="mailto:admin@pandevlaw.com">
                admin@pandevlaw.com
              </a>
              oder nutzen Sie unser{' '}
              <a className="link-primary" href="/contact/">
                Kontaktformular
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <CtaDeck
        title="Vereinbaren Sie noch heute einen Termin mit unserem Anwalt"
        buttonText="Sprechen Sie mit unserem Anwalt"
      />
    </Layout>
  );
}

export default German;
